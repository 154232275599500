/**
 *  This is our Axios configuration and start of the connection.
 *  It checks local storage to see if there is a JWT token present for future API calls.
 *  Our backend applications use JWT authentication and most calls will require this to be set.
 *
 *  We can also set which headers are required. This will feed into our other api calls which are
 *  separated into different files for easy managment and readiibility
 */

import AppService from '@/services/app';
import axios from 'axios';
import Store from '@/store/index';


axios.defaults.withCredentials = true;

export default () => {

  const thisAxios = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });

  // check for any errors and handle the
  thisAxios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    // tslint:disable-next-line:no-console
    //console.log(`api.ts - 33 - error`, error);
    if (error.response && error.response.status && 500 >= error.response.status && 503 <= error.response.status) {  
      
      // show could not connect error and stay on whatever screen you are (because we are already on AUTH portal)
      AppService.errorHandler(Store.getters['app/messages'].couldNotConnect);
      return Promise.reject(); // dont send error back because we want the sessionTimeout error to show

    } else {
      // tslint:disable-next-line:no-console
      //console.log(`api.ts - 42 - `, error);
      return Promise.reject(error);
    }
  });

  return thisAxios;
};
