/**
 *   This is a service for a specific store module.
 *   We separate our files for easy managment and readibility.
 *   We export this as a service to be used in other areas of the application.
 */

import Store from '@/store/index';

export default {

  async logSupportDebug(message: string) {
    if (!process.env.VUE_APP_ISLIVE) {
      // tslint:disable-next-line:no-console
      console.log(`${new Date().toISOString().split('.')[0].replace('T', ' ')} - ` + message);
    }
  },

  async errorHandler(message: string) {
    Store.dispatch('app/snackbar', {
      show: true,
      text: message,
      color: 'error',
      icon: Store.getters['app/icons'].error,
    });
  },

  async errorHandlerWithCustomTimeout(message: string, timeout: number) {
    Store.dispatch('app/snackbarWithCustomTimeout', {
      show: true,
      text: message,
      color: 'error',
      icon: Store.getters['app/icons'].error,
      timeout: timeout,
    });
  },

  async successHandler(message: string) {
    Store.dispatch('app/snackbar', {
      show: true,
      text: message,
      color: 'success',
      icon: Store.getters['app/icons'].success,
    });
  },

  async successHandlerWithCustomTimeout(message: string, timeout: number) {
    Store.dispatch('app/snackbarWithCustomTimeout', {
      show: true,
      text: message,
      color: 'success',
      icon: Store.getters['app/icons'].success,
      timeout: timeout,
    });
  },

};
