/**
 *   This is a service for a specific store module which uses the api.ts Axios instance as a base.
 *   We separate our files for easy managment and readibility.
 *   We export this as a service to be used in other areas of the application.
 */

import Api from '@/services/api';
import AppService from '@/services/app';
import Router from '@/router/index';

export default {

  register(params: { 
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    portalTypeId: number,
  }) {
    return Api().post('/register', params);
  },

  verify(params: { 
    token: string,
    username: string,
  }) {
    return Api().post('/verify', params);
  },

  acceptInvitation( formData: FormData ) {
    return Api().post('/auth/accept', formData);
  },

  login(params: { 
    username: string, 
    password: string,
  }) {
    return Api().post('/login', params);
  },  

  forgotPassword(params: { 
    username: string,
  }) {
    return Api().post('/forgot', params);
  },
  
  setPassword(params: { 
    username: string, 
    password: string, 
    token: string,
  }) {
    return Api().post('/set_password', params);
  },

};
